.am-block {
    overflow: auto;
    .am-image {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: @color-interface-toolbar;
        float: left;
        line-height: 120px;
        text-align: center;
        font-size: 1.2em;
        overflow: hidden;
        img {
            height: 100%;
            vertical-align: top;
        }
    }
    .am-contact {
        float: left;
        margin-left: 15px;
        margin-top: 30px;
        .am-name {
            font-size: 1.1em;
            font-weight: bold;
            a {
                color: @color-interface-text;
            }
        }
        .fa { margin-right: 5px; }
    }
}

footer {
    .am-block {
        .am-image {
            background: white;
        }
    }
}
