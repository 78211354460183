.page-invoice {
    .actions-links {
        .make-xs-column(4);
        > button, a {
            width: 100%;
            margin-bottom: 10px;
        }
        p {
          font-size: .85em;
          color: lighten(@color-interface-text, 10%);
          font-style:italic;
        }
    }
    .details {
        .make-xs-column(8);
    }
    .shipping-address, .billing-address, .remit-address {
        .make-xs-column(4);
    }
    section {
        &.notice {
            h2 {
                font-size: 1.2em
            }
            p {
                font-size: .9em
            }
        }
    }
}
