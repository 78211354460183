@import "global-variables";

.order-summary{
  background-color: #ffffff;
  padding: 10px 15px;
  margin: 8px;
  box-shadow: 0 1px 2px @color-interface-toolbar;

  h1{
    padding-bottom: 6px;
  }
}