@import "global-variables";

.required {
  &:before {
    content:"*";
    font-size:.8em;
    margin-right:2px;
    color:@brand-danger;
  }
}
.align-with-input {
  margin-top:6px;
}