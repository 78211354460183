.btn-group.fancy-dropdown {
    display:block;
    cursor:default;
    overflow:hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
        &.line {
            display:block;
        }
    }
    .dropdown-toggle {
        width:100%;
        border: 1px solid @input-border;
        padding:10px;
        display:table;
        //table-layout:fixed;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
        -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
        -webkit-border-radius:@border-radius-base;
        -moz-border-radius:@border-radius-base;
        border-radius:@border-radius-base;
        -webkit-transition: all .05s;
        -moz-transition: all .05s;
        transition: all .05s;
        .caret-container {
            display:table-cell;
            vertical-align:middle;
            padding-left:10px;
        }
    }
    .dropdown-menu {
        display:block;
        width:100%;
        padding:0px;
        -webkit-border-radius:@border-radius-base;
        -moz-border-radius:@border-radius-base;
        border-radius:@border-radius-base;
        opacity:0;
        -webkit-transition: all .1s;
        -moz-transition: all .1s;
        transition: all .1s;
        li {
            padding:10px;
            border-top: 1px solid @input-border;
            -webkit-transition: all .05s;
            -moz-transition: all .05s;
            transition: all .05s;
            &:first-child {
                border:none;
                -webkit-border-radius:@border-radius-base @border-radius-base 0  0;
                -moz-border-radius:@border-radius-base @border-radius-base 0  0;
                border-radius:@border-radius-base @border-radius-base 0  0;
            }
            &:last-child {
                -webkit-border-radius:0  0 @border-radius-base @border-radius-base;
                -moz-border-radius:0  0 @border-radius-base @border-radius-base;
                border-radius:0  0 @border-radius-base @border-radius-base;
            }
            &:hover {
                color:#fff;
                background-color:@link-color;
            }
            &:active {
                background-color:@link-hover-color;
            }
        }
    }
    &.open {
        overflow:visible;
        .dropdown-toggle {
            border-color: @input-border-focus;
            -webkit-box-shadow: 0 0 5px @input-border-focus, inset 0 1px 2px rgba(0, 0, 0, 0.125);
            -moz-box-shadow: 0 0 5px @input-border-focus, inset 0 1px 2px rgba(0, 0, 0, 0.125);
            box-shadow: 0 0 5px @input-border-focus, inset 0 1px 2px rgba(0, 0, 0, 0.125);
        }
        .dropdown-menu {
            opacity:1;
        }
    }
}
