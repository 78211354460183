@import "global-variables";

.page-product {

    .blt-wrap section { width: 940px; margin: 30px auto }
    .specifications table { width: 30%; }

    /* This is a hack until we get to re-write the product
    page to properly use the grid system. Ick */

    .specifications {
        clear: both;
        margin: 15px;
    }

    .content-main {
        padding-bottom: 15px;
        .product-image {
            .make-xs-column(4);
            img {
                width: 100%
            }
            .product-image-caption {
                font-size: .85em;
                color: lighten(@color-interface-text, 15%);
                position: absolute;
                background: hsla(0, 100%, 100%, 0.9);
                bottom: 10px;
                padding: 5px;
                left: 10%;
                width: 80%;
                border-radius: 5px;
            }
        }
    }
    .product-details {
        .make-xs-column(5);
        h1 {
            font-size: 2em;
        }
        .product-price {
            position: relative;
            .product-price-actual {
                font-size: 2.2em;
                display: inline-block;
                color: @color-brand-callout;
            }
            .product-price-retail{
                font-size:1em;
                display: inline-block;
                text-decoration: line-through;
                color: lighten(@color-interface-text, 30%);
            }
            .product-price-msg {
                background: #fff;
                top: 0;
                left: 0;
                position: absolute;
                line-height: 44px;
                width: 100%;
                .opacity(0);
                transition: opacity .5s ease, display .5s ease;
                -webkit-transition: opacity .5s ease, display .5s ease;
            }
            .product-price-disclaimer {
                font-size: .9em;
            }
            &.state-msg {
                .product-price-msg {
                    .opacity(1);
                }
            }
        }
        .product-icons {
         /*   margin-top: 20px;
            color: lighten(@color-interface-text, 20%);
            font-size: .9em;*/
          display:none;
        }
        .product-permutations {
            .make-row();
            margin-top: 20px;
            .form-group {
                .make-xs-column(6);
                select {
                    .input-sm();
                }
                label {
                    font-weight: 600;
                    font-size: .9em
                }
            }
        }
    }
    .product-actions {
        .make-xs-column(3);
        //margin-left: (1 / 12) * 100%;
        .action-panel {
            background: lighten(@color-interface-toolbar, 3%);
            border: 1px solid darken(@color-interface-toolbar, 5%);
            padding: 15px;
        }
        .main-action {
            label { width: 13%; display: block; float: left; line-height: 30px; height: 30px;}
            input { width: 22%; margin-left: 2%; display: block; float: left; text-align: center; padding: 5px 5px }
            select, .uom-no-select { width: 61%; margin-left: 2%; display: block; float: left}
            .uom-no-select {
                height: 30px;
                line-height: 30px;
                font-size: .9em;
                padding-left: 5px;
            }
            button { margin-top: 5px }
            margin-bottom: 15px;
            .cut-off-notice, .leadtime-notice {
                font-size: .85em;
                color: lighten(@color-interface-text, 20%);
                margin-top: 10px;
            }
        }
        .secondary-actions {
            font-size: .95em;
            li {
                margin-bottom: 10px;
                color: @color-brand-primary;
                cursor: pointer;
                &:hover {
                    color: darken(@color-brand-primary, 10%);
                }
                .transition(color ease 1s);
            }
            border-top: 1px solid darken(@color-interface-toolbar, 5%);
            padding-top: 15px;
            &.state-inactive {
                li {
                    color: mix(lighten(@color-interface-toolbar, 3%), @color-brand-primary, 50%);
                    cursor: not-allowed;
                    &:hover {
                        color: mix(lighten(@color-interface-toolbar, 3%), @color-brand-primary, 50%);
                    }
                }
            }
        }
        .questions {
            font-size: .85em;
            color: lighten(@color-interface-text, 10%);
            margin-top: 10px;
            a { display: block }
        }
        button, .btn { width: 100%; }
    }
    .description {
        .make-xs-column(9);
        .dl-horizontal {
            font-size: .9em;
        }
    }
}
