@import "global-variables";

body.select-location {
    background: @color-interface-toolbar;
}

.select-location {

}

#location-list {
  width:80%;
  .location {
  /*  border-top: 1px solid @color-interface-toolbar;*/
    padding:8px;
  }
  p {
    margin:0;
    padding:0;
  }
 /* border-bottom:1px solid @color-interface-toolbar;*/
}