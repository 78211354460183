.alert-action {
	.alert-variant(lighten(@color-interface-toolbar, 3%); @color-interface-toolbar; @color-interface-text);
}

.alert {
	-webkit-transition: border-color .5s ease, background-color .5s ease, color .5s ease;
	transition: border-color .5s ease, background-color .5s ease, color .5s ease;
	position: relative;

	.alert-actions {
		position: absolute;
		right: 5px;
		top: 5px;
		height: 26px;
		line-height: 26px;

		button {
			height: 100%;
			.btn-info();
			margin-top: -2px;
		}
	}
}

.alert-bar {
    .alert-variant(
        @alert-warning-bg;
        darken(@alert-warning-border, 30%);
        darken(@alert-warning-text, 30%)
    );
    border-width: 0 0 1px 0;
    margin: 0;
}
