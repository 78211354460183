body.sign-in {
    background: @color-interface-toolbar;
}

.sign-in {
    .sign-in-module {
        background: #fff;
        margin: 35px auto;
        width: 780px;
        box-shadow: 0 0 5px darken(@color-interface-toolbar, 15%);
        height: 450px;
        position: relative;
        .sign-in-brand, .sign-in-actions, .sign-in-forgot, .sign-in-get-started {
            width: 50%;
            position: absolute;
            top: 0;
            left: 0;
            height: 450px;
        }
        .sign-in-brand {
            background: black;
            img {
                .transition(opacity .25s ease);
            }
            .sign-in-tagline {
                position: absolute;
                text-align: center;
                bottom: 30px;
                text-shadow: 0 1px 4px #000;
                width: 90%;
                font-size: 1.8em;
                color: #fff;
                line-height: 1.2em;
                left: 5%;
            }
            ul {
                overflow: auto;
                margin-bottom: 20px;
                li {
                    width: 50%;
                    float: left;
                }
            }
        }
        .sign-in-actions {
            left: 390px;
            background: white;
            z-index: 3;
            .transition(left .25s ease);
            .site-logo {
                width: 175px;
                margin: 40px auto 20px auto;
                img { width: 100%; height: auto; }
            }
            .sign-in-form {
                margin: 0 30px;
                .sign-in-action {
                    .make-row();
                    font-size: .95em;
                    .sign-in-button{
                        .make-xs-column(4);
                        input { width: 100% }
                    }
                    .sign-in-link {
                        .make-xs-column(8);
                        line-height: 34px;
                    }
                }
                .sign-in-message {
                    font-size: .9em;
                    margin-top: -10px;
                    margin-bottom: 20px;
                    color: @color-semantic-bad;
                }
            }
            .next-steps {
                margin: 30px 30px 0 30px;
                text-align: center;
                background: white;
                .next-step-question {
                    font-weight: bold;
                }
            }
        }
        .sign-in-forgot {
            left: 390px;
            background: lighten(@color-interface-toolbar, 3%);
            width: 390px;
            z-index: 1;
            padding: 30px;
            label {
                font-weight: bold;
            }
            .forgot-pass-form {
                margin: 30px 0;
                .alert {
                    margin-top: 20px;
                }
            }
        }
        .sign-in-get-started {
            left: 390px;
            background: lighten(@color-interface-toolbar, 3%);
            width: 390px;
            z-index: 1;
            padding: 30px;
            label {
                font-weight: bold;
            }
            .form-group {
                float: left;
                width: 100%;
                &.phone-number { width: 47%; margin-right: 6% }
                &.email-address { width: 47%; }
                &.city { width:40%; margin-right: 6%; }
                &.state { width:25%; margin-right: 6%; }
                &.zip { width:23%; }
                &.sign-in-action { float: right; margin-top: 10px }
            }
        }
        &.state-forgot {
            .sign-in-brand {
                img {
                    .opacity(.6);
                }
            }
            .sign-in-actions {
                left: 0px;
                box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.2);
            }
        }
        &.state-get-started {
            .sign-in-actions {
                left: 0px;
            }
        }
        &.reset-password {
            .sign-in-brand {
                background: @color-brand-primary;
                color: white;
                padding: 30px;
                h1 {
                    margin: 0 0 20px 0;
                }
            }
            .sign-in-actions {
                .site-logo {
                    width: 115px;
                    margin: 30px auto 30px auto;
                }
                .sign-in-form {
                    margin: 0 30px;
                    .sign-in-action {
                        .make-row();
                        font-size: .95em;
                        .sign-in-button{
                            width: 55%;
                            margin-left: 45%;
                        }
                    }
                }
            }
        }
    }
    h1 {
        font-size: 1.6em;
        text-align: center;
        font-weight: 400;
        margin-top: -30px;
    }
    .sign-in-bullet {
        .make-xs-column(4);
        padding-top: 130px;
        text-align: center;
        &:before {
            content: "\e606";
            font-family: ds;
            color: contrast(black, white, @color-brand-primary);
            text-shadow: 0 0 5px darken(@color-interface-toolbar, 15%);
            font-size: 7em;
            position: absolute;
            left: 50%;
            margin-left: -42px;
            top: 0;
        }
        &:after {
            font-family: FontAwesome;
            color: @color-brand-primary;
            position: absolute;
            left: 50%;
            margin-left: -28px;
            font-size: 4em;
            top: 29px;
        }
        &.cart {
          &:after {
              content: "\f07a";
          }
        }
        &.tag {
            &:after {
                content: "\f02b";
                margin-left: -21px;
                top: 35px;
            }
        }
        &.check {
            &:after {
                content: "\f00c";
                margin-left: -26px;
                top: 32px;
            }
        }
        &.build {
            &:after {
                content: "\f0ad";
                margin-left: -25px;
                top: 28px;
            }
        }
        &.equip {
            &:after {
                content: "\f16b";
                margin-left: -27px;
                top: 32px;
            }
        }
        &.run {
            &:after {
                content: "\f0e4";
                margin-left: -27px;
                top: 26px;
            }
        }
        &.arrow {
            &:after {
                content: "\e60c";
                margin-left: -25px;
                top: 45px;
                font-family: ds;
                font-size: 35px;
            }
        }
        &.dollar {
            &:after {
                content: "\f155";
                margin-left: -16px;
                top: 30px;
            }
        }
    }
    [role='details'] .container {
        width: 780px;
        margin-bottom: 35px;
    }
    .sign-up-action {
        font-size: 1.142em;
        text-align: center;
        margin-top: 30px;
        a {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
