.lt-ie8 {
	body {
		> * {
			display: none;
		}

		.unsupported-message {
			font-size: 2.5em;
			width: 80%;
			margin: 50px auto 0 auto;
			text-align: center;
			display: block;
			font-style: normal;
		}

		zoom: ~'expression( this.runtimeStyle.zoom="1", blah = document.createElement("i"), blah.innerHTML = "<h1>We\'re Sorry</h1><p>Your browser is no longer supported.</p><p>Please upgrade to a newer version of Internet Explorer or contact us at 1-800-634-7328 for assistance.</p>", this.insertBefore( blah, this.firstChild ).className="unsupported-message" )';
		/*zoom: ~'expression( this.runtimeStyle.zoom="1", blah = document.createElement("i"), blah.appendChild(document.createTextNode("browser no")), this.insertBefore( blah, this.firstChild ).className="unsupported-message" )';*/
		background: @color-interface-toolbar;

		&.sign-in {
			section[role='main'] {
				display: block;
			}

			.unsupported-message {
				display: none;
			}

			.sign-in-module {
				.sign-in-actions {
					width: (780px / 2);

					.sign-in-form {
						.sign-in-action {
							.sign-in-link {
								width: 190px;
							}

							.sign-in-button {
								input {
									padding: 6px 0;
								}

								padding: 0;
								width: 127px;
							}
						}
					}

					.next-steps {
						margin-top: 80px;
					}
				}

				.sign-in-forgot {
					width: ((780px / 2) - 60px);
					height: 390px;

					.input-group {
						.form-control {
							width: 181px;
							height: 24px;
						}
					}
				}

				.form-group {
					overflow: visible;

					label {
						line-height: normal;
					}

					input {
						padding: 0;

						&.form-control {
							padding: 10px;
							width: 310px;
							height: 20px;
						}
					}
				}
			}
		}
	}
}

/* Fix for IE9 -- Hovering on a input
	button group would expand */
#promotional-codes .form-control {
	display: block !important;
}

.show-ie8-only {
	display: none;
}

html.eq-ie8 .show-ie8-only {
	display: block;
}
