@import "global-variables";

.add-to-car-sidebar {display:none;}
.product-listing-holder {
    margin: 0;
     /* 1px of bottom padding in order to workaround a jquery/browser bug calculating the height with collapsing margins, used to affix the add to cart button */
    padding: 0 0 1px 0;

    // Global Styles:
    .product-price {
        color: @color-brand-callout;
        font-weight: bold;
    }
    .product-totals {
        display: none;
    }
    .product-price-retail {
        text-decoration: line-through;
        color: lighten(@color-interface-text, 40%);
        font-size: 12px;
        font-weight: normal;
    }
    .product-data {
        color: lighten(@color-interface-text, 10%);
    }
    .product-name {
        a {
            //color: corp-accent;
            font-weight: bold;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .product-image {
        position: relative;
        img {
            opacity: 1;
            transition: 1s opacity;
            position: relative;
            z-index: 5;
            &.product-image-loading {
                opacity: 0;
            }
        }
        .product-image-placeholder {
            position: absolute;
            top: 0;
            left: 0;
            border: 3px dashed lighten(@corp-gray, 50%);
            border-radius: 20px;
            z-index: 1;
        }
    }
    .product-options, .product-leadtime-notice, .product-avail-remove { display: none; }
    .product-category{
        background: @color-interface-toolbar;
        padding: 5px 10px;
        margin: 10px 0 5px 0;
        font-size: 13px;
        clear: both;
    }
    @media screen{
    &.state-grid {
        .product-listing-header, .product-listing-actions, .product-attributes, .product-rating { display:none; }
        .horizontal-list li:before { content: ""; }
        .product-listing-holder-row{
            .make-row(50px);
            margin-bottom: 30px;
            .product-supplier, .product-mfg-no, .product-note, .product-price-retail, .product-qty,
            .product-add-to-cart, .product-uom, .product-pack-size, .product-brand {
                display: none;
            }
            .product-listing {
                .make-xs-column(3, 50px);
                overflow: hidden;
                .product-image {
                    img { width: 100%; height: auto }
                }
                .product-extras, .product-attributes {display: none}
                .product-data, .product-price { width: 90% }
                .product-name {
                    margin-top: 5px;
                    a {
                        font-size: 15px;
                        font-weight: normal
                    }
                }
                .product-sup-no {
                    display: inline-block;
                    font-size: 13px;
                    color: lighten(@color-interface-text, 20%);
                    margin: 2px 0;
                    &:before { content: "#"; }
                }
                .product-price {
                    .product-price-your {
                        font-size: 15px;
                        display: inline-block;
                    }
                    .product-uom {
                        display: inline-block;
                        margin-left: 3px;
                        color: @color-interface-text;
                        font-size: 13px;
                        text-transform: lowercase;
                        font-weight: normal;
                    }
                }
                &.state-unavailable {
                    .product-price { display: none; }
                    //.product-availability { a { display: none; } }
                }
                &.state-available {
                    .product-availability { display: none; }
                }
            }
        }
    }
    &.state-grid-five {
        .product-listing-holder-row{
            .product-listing {
                .make-xs-column(3, 50px);
                width: 20%;
            }
        }
    }
    &.state-detail {
        .product-category{margin: 15px 0}
        &.state-detail-list{
            .product-options{display:inline;font-weight: normal;}
        }
        &.state-detail-display {
            .product-data {
                width: 85%;
            }
        }
        .horizontal-list {
            margin-top:5px;
        }
        .product-rating {
            height: 21px;
            margin-top: 5px;
        }
        .product-attributes {
            margin-top: 5px;
            .ds-icons {
                width: 50%;
                float: left;
            }
        }
        .product-listing-header, .product-qty-remove, .product-qty-update, .product-supplier { display: none; }
        .product-listing {
            overflow: auto;
            clear: both;
            + .product-listing {
                border-top: 1px dotted darken(@color-interface-toolbar, 20%);
                padding-top: 15px;
                margin-top: 15px;
            }
            &.state-unavailable {
                .product-ordering, .product-price {
                    display: none;
                }
                .product-availability {
                    float: left;
                    margin-left: 3%;
                    width: 33%;
                    text-align: right;
                    a {
                        display: block;
                        font-size: 12px;
                    }
                }
            }
            &.state-available {
                .product-availability { display: none; isithere: 169 }
            }
            .product-image {
                width: 12%;
                float: left;
                img { width: 100%; height: auto }
            }
            .product-data {
                width: 51%;
                float: left;
                margin-left: 1%;
                font-size: 13px;
                .product-name { font-size: 14px }
                .product-supplier {
                    &:before {
                        content: "Supplier: ";
                        color: lighten(@color-interface-text, 20%);
                    }
                }
                .product-sup-no {
                    color: lighten(@color-interface-text, 20%);
                    &:before {
                        content: "#";
                    }

                }
                .product-brand {
                    display: inline-block;
                    &:before { content: "by "; color: lighten(@color-interface-text, 20%); }
                }
                .product-mfg-no {
                    //display: none;
                    //&:before { content: " (#"; };
                    //&:after { content: ")"; }
                    &:before { content: "Manufacturer #"; };
                }
                .product-uom { display: inline-block }
                .product-pack-size {
                    display: inline-block;
                    &:before{ content: " of "; }
                }
            }
            .product-note {
                width: 8%;
                margin-left: 3%;
                float: left;
                .ds-glyph {
                    float: left;
                    display:none;
                }
            }
            .product-price {
                width: 17%;
                float: left;
                position:relative;
                left:10%;
                margin-left: 1%;
                text-align: right;
                .product-price-your {
                    font-size: 15px;
                }
                .product-uom { display: none; }
            }
            .product-ordering {
                width: 5%;
                float: left;
                left:11%;
                margin-left: 2%;
                text-align: right;
                position: relative;
                .product-qty {
                    input {
                        .form-control();
                        padding-left: 0;
                        padding-right: 0;
                        width: 100%;
                        text-align: center;
                    }
                }
                .product-add-to-cart {
                    button {
                        position: absolute;
                        right: 0;
                        width: 100px;
                        top: 45px;
                        .transition(linear .2s opacity);
                        &.state-hiding { .opacity(0) }
                        &.state-showing { .opacity(1) }
                    }
                }
            }
            position: relative;
            &:hover {
                .product-extras {.opacity(1);}
            }
            .product-extras {
                width: 100px;
                text-align: right;
                position: absolute;
                right: 0;
                bottom: 15px;
                height: 25px;
                .transition(linear .3s opacity);
                .opacity(.4);
            }
        }
        .product-listing-holder-row {
            + .product-listing-holder-row {
                .product-listing:first-child {
                    border-top: 1px dotted darken(@color-interface-toolbar, 20%);
                    padding-top: 15px;
                    margin-top: 15px;
                }
            }
        }
    }
    &.state-compact {
        .product-qty-remove, .product-qty-update { display: none }
        .product-attributes, .product-rating { display:none; }
        .horizontal-list {
            li {
                &:before { content: ""; }
                float: none;
                display: inline;
                + li {
                    &:before { margin: 0 }
                }
            }
            display: inline;

        }
        .product-listing-header {
            font-size: 11px;
            border-bottom: darken(@color-interface-toolbar, 20%) 1px solid;
            padding-bottom: 5px;
            margin-bottom: 5px;
            overflow: auto;
            color: lighten(@color-interface-text, 20%);
            .product-name { width: 30%; float: left;  }
            .product-brand { width: 20%; float: left; margin-left: 1%;  }
            .product-mfg-no { width: 10%; float: left; margin-left: 1%; }
            .product-sup-no { width: 10%; float: left; margin-left: 1%; }
            .product-uom { width: 10%; float: left; margin-left: 1%; }
            .product-price-your, .product-totals { width: 9%; float: left; margin-left: 1%; text-align: right; display: block; }
            .product-qty { width: 5%; float: left; margin-left: 2%; text-align: center }
            .product-note, .product-permutations, .product-attributes { display: none }
        }
        .product-listing-actions { display: none; }
        .product-listing {
            overflow: auto;
            clear: both;
            border-bottom: 1px solid darken(@color-interface-toolbar, 10%);
            padding: 5px 0;
            .product-image, .product-price-retail, .product-add-to-cart,
            .product-pack-size, .product-supplier, .product-uom, .product-rating, .product-attributes {
                display: none;
            }
            font-size: 12px;
            .product-extras, .product-note { display: none; }
            &.state-unavailable {
                .product-ordering, .product-price {
                    display: none;
                }
                .product-availability {
                    float: left;
                    margin-left: 3%;
                    width: 12%;
                    text-align: right;
                    a {
                        display: block;
                        font-size: 12px;
                    }
                }
            }
            &.state-available {
                .product-availability { display: none; isithere: 343 }
            }
            .product-name {
                width: 30%;
                float: left;
                .product-options { font-weight: normal; display: inline; }
                a {
                    font-weight:normal; color:black; cursor: pointer; pointer-events: none;
                }
            }
            .product-brand { width: 20%; float: left; margin-left: 1%;  }
            .product-mfg-no { width: 10%; float: left; margin-left: 1%; font-size: 11px }
            .product-sup-no {width: 10%; float: left; margin-left: 1%; font-size: 11px}
            .product-uom {width: 10%; float: left; margin-left: 1%; font-size: 11px}
            .product-price-your, .product-totals { display: block; width: 9%; float: left; margin-left: 1%; text-align: right;}
            .product-qty {
                width: 5%;
                float: left;
                margin-left: 2%;
                input {
                    width: 100%;
                    .form-control();
                    .input-sm();
                    text-align: center;
                    padding-left: 0;
                    padding-right: 0;
                    font-size: 11px
                }
            }
            .product-data, .product-price, .product-ordering { display: inline }
        }
    }
    &.state-compact-strict {
		.product-attributes, .product-rating { display:none; }
        .horizontal-list li:before { content: ""; }

        &.state-edit{
            .product-listing-header{
                .product-qty, .product-price-your{display:none;}
            }
            .product-listing{
                .product-price-your {
                    left: 85%;
                    width: 9%;
                    text-align: right;
                }
                .product-qty input{display:none;}
                .product-qty {
                    width:5%;
                    left:95%;
                    .product-qty-remove{ display: block; padding: 5px 0; font-size: 1em; }
                    .product-qty-update { display: none; }
                }
                &.state-unavailable{
                    .product-availability{left:80%;}
                    .product-ordering .product-qty .product-qty-remove{display:block;}
                }
            }

            .product-listing-actions{display:none;}
        }
        .product-image, .product-price-retail, .product-add-to-cart,
        .product-pack-size, .product-supplier, .product-permutations, .product-qty-remove, .product-qty-update,
        {
            display: none;
        }
        .product-listing-header {
            font-size: 11px;
            border-bottom: darken(@color-interface-toolbar, 20%) 1px solid;
            padding-bottom: 5px;
            margin-bottom: 5px;
            color: lighten(@color-interface-text, 20%);
            position: relative;
            height: 25px;
            div {
                position: absolute;
                top: 0;
                line-height: 25px;
                height: 25px;
            }
            .product-name { width: 39%; left: 1%; font-weight: 400; }
            .product-brand { width: 19%; left: 41%;  }
            .product-mfg-no { width: 8%; left: 61%; }
            .product-sup-no { width: 8%; left: 70%; }
            .product-uom { width: 8%; left: 79%; }
            .product-note {display:none;}
            .product-price-your { display:none; }
            .product-qty { width: 4%; left: 96%; text-align: center }
        }
        .product-listing {
            height: 34px;
            position: relative;
            border-bottom: 1px solid darken(@color-interface-toolbar, 10%);
            &:nth-child(even) { background: lighten(@color-interface-toolbar, 5%); border-bottom: 1px solid darken(@color-interface-toolbar, 15%); }
            .product-name, .product-brand, .product-mfg-no, .product-sup-no, .product-uom,
            .product-price-your, .product-qty, .product-availability {
                height: 34px;
                line-height: 34px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                top: 0;
                font-size: 12px;
                position: absolute;
            }
            .product-extras { display: none; }
            &.state-unavailable {
                .product-price {
                    display: none;
                }
                .product-ordering{
                  .product-qty-input{display:none;}
                  .product-qty-remove{display:none;}
                }
                .product-availability {
                    position: absolute;
                    left: 86%;
                    width: 14%;
                    text-align: right;
                    font-size: 12px;
                    a {
                        font-size: 12px;
                    }
                }
            }
            &.state-available {
                .product-availability { display: none; }
            }
            .hover-box-reveal {
                width: auto;
                background: lighten(@corp-gray, 60%);
                z-index: 4;
                border: 1px lighten(@corp-gray, 35%) solid;
                top: -1px;
                padding: 0 10px;
                margin-left: -11px;
                border-radius: 5px;
                box-shadow: 0 0 10px lighten(@corp-gray, 30%)
            }
            .product-name {
                left: 1%;
                width: 39%;
                &:hover {
                    .hover-box-reveal;
                }
            }

            .product-brand {
                left: 41%;
                width: 19%;
                &:hover {
                    .hover-box-reveal;
                }
            }

            .product-mfg-no {
                left: 61%;
                width: 8%;
                &:hover {
                    .hover-box-reveal;
                }
            }
            .product-sup-no {
                left: 70%;
                width: 8%;
                &:hover {
                    .hover-box-reveal;
                }
            }
            .product-uom {
                left: 79%;
                width: 8%;
            }
            .product-note {
                left: 69%;
                .ds-glyph {
                    height: 34px;
                    line-height: 34px;
                    float: left;
                    display:none;
                    &:before {
                        height: 34px;
                        line-height: 34px;
                        font-size: .9em;
                    }
                }
            }
            .product-price-your {
                left: 86%;
                width: 9%;
                text-align: right;
            }

            .product-price {
                .product-uom {
                    display: none;
                }
            }
            .product-qty {
                left: 96%;
                width: 4%;
                top: 2px;
                input {
                    width: 100%;
                    .form-control();
                    .input-sm();
                    text-align: center;
                    padding-left: 0;
                    padding-right: 0;
                    font-size: 11px
                }
            }
        }
    }
    &.state-grid-carousel {
        .product-listing-holder-row{
            float: left;
            clear: none;
            margin: 0 -25px;
        }
    }
    &.state-detail, &.state-compact-strict, &.state-compact {
        //padding-bottom : 69px;
        position: relative;
        .product-listing-actions {
            padding: 10px 0;
            height: 53px;
            &.state-locked {
                position: fixed;
                bottom: 0;
                z-index: 10;
                background: @color-interface-toolbar;
                box-shadow: inset -1px 2px 15px -3px darken(@color-interface-toolbar, 20%);
                width: 100%;
                left: 0;
                .btn-primary {
                    position: absolute;
                    left: 50%;
                    margin-left: 368px;
                    top: 10px;
                }
            }
        }
    }
    &.state-detail-simple {
        .product-listing {
            .product-image { width: 8%; } /* less 4% */
            .product-data {
                width: 45%;
                .product-supplier {
                    display: none;
                }
                .product-leadtime-notice { display: block }
            }
            .product-options { display: inline; font-weight: normal }
            .product-ordering {
                margin-left: 8%;
                left:0%;
                width: 6%;
                position: relative;
                input { position: relative; z-index: 2; }
                .product-qty {
                    .product-qty-remove, .product-qty-update {
                        display: block;
                        font-size: .7em;
                        padding: 0;
                    }
                    .product-qty-update {
                        position: absolute;
                        top: 0;
                        left: 0px;
                        width: 100%;
                        padding: 5px 0px 2px;
                        z-index: 1;
                        .transition(top 250ms ease);
                        &.state-update {
                            top: 28px;
                        }
                    }
                }
            }
            .product-price {
               left:0%;
                .product-price-your {
                    font-size: 1em;
                }
            }
            .product-note, /* 8%, 3% */
            .product-extras { display: none }
            .product-totals { display: block; width: 14%; float: left; text-align: right; }
            &.state-unavailable {
                .product-totals { display: none; }
                .product-availability { width: 43%; }
                .product-avail-remove { display: block; }
            }
        }
        .product-listing-actions { display: none }
    }
    &.state-detail-snippet {
        .product-listing {
            .product-image { width: 22%; } /* less 4% */
            .product-data {
                width: 77%;
                .product-supplier {
                    display: none;
                }
            }
            .product-options { display: block; }
            .product-ordering,
            .product-totals,
            .product-price,
            .product-note, /* 8%, 3% */
            .product-extras { display: none }
        }
        .product-listing-actions { display: none }
    }
    }
    @media print {
        &.state-compact ,&.state-grid, &.state-detail, &.state-compact-strict {
            .product-qty-remove, .product-qty-update,
            .product-attributes, .product-rating { display:none; }

            .horizontal-list {
                li {
                    &:before { content: ""; }
                    float: none;
                    display: inline;
                    + li {
                        &:before { margin: 0 }
                    }
                }
                display: inline;
            }

            .product-listing-header {
                font-size: 11px;
                border-bottom: darken(@color-interface-toolbar, 20%) 1px solid;
                padding-bottom: 5px;
                margin-bottom: 5px;
                overflow: hidden;
                color: lighten(@color-interface-text, 20%);
                .product-name { position: relative; width: 30%; float: left; }
                .product-brand { position: relative; width: 20%; float: left; margin-left: 1%;}
                .product-mfg-no { position: relative; width: 10%; float: left; margin-left: 1.5%;}
                .product-sup-no { position: relative; width: 10%; float: left; margin-left: 1.5%;}
                .product-uom { position: relative; width: 6%; float: left; margin-left: 1.5%;}
                .product-price-your, .product-totals { position: relative; width: 79%; left: 83%; float: left; text-align: right; display: block; }
                .product-note, .product-permutations, .product-attributes, .product-qty { display: none }
            }
            /* If the overflow on the holder-row is not set to hidden, Internet Explorer starts thinking it's a great idea
             * to start adding scrollbars to divs on the print view of the page when the div is sufficiently tall.*/
            .product-listing-holder-row { overflow: hidden; }
            .product-listing-actions { display: none; }
            .product-listing {
                position: relative;
                min-height: 40px;
                page-break-inside: avoid;
                clear: both;
                border-bottom: 1px solid darken(@color-interface-toolbar, 10%);
                padding: 5px 0;
                font-size: 12px;

                .product-image, .product-price-retail, .product-add-to-cart, .product-pack-size,
                .product-supplier, .product-rating, .product-attributes, .product-extras, .product-note {
                    display: none;
                }

                .print-row-item {
                    position: relative;
                    display: inline-block;
                }

                &.state-unavailable {
                    .product-ordering, .product-price {
                        display: none;
                    }
                    .product-availability {
                        .print-row-item;
                        margin-left: 1%;
                        width: 12%;
                        a {
                            display: none;
                        }
                    }
                }

                &.state-available {
                    .product-availability { display: none; isithere: 343 }
                }

                .product-name {
                    width: 30%;
                    .print-row-item;
                    .product-options { font-weight: normal; display: inline; }
                    a {
                        font-weight:normal; color:black; cursor: pointer; pointer-events: none;
                    }
                }

                .product-brand { .print-row-item; width: 20%; margin-left: 1%; }
                .product-mfg-no { .print-row-item; width: 10%; margin-left: 1%; font-size: 11px; word-wrap: break-word; }
                .product-sup-no { .print-row-item; width: 10%; margin-left: 1%; font-size: 11px; word-wrap: break-word; }
                .product-uom { .print-row-item;  width: 6%; margin-left: 1%; font-size: 11px; word-wrap: break-word;}
                .product-price-your, .product-totals { .print-row-item; width: 9%; text-align: right; }
                .product-qty {
                    width: 5%;
                    margin-left: 1%;
                    .print-row-item;
                    input {
                        width: 100%;
                        .form-control();
                        .input-sm();
                        text-align: center;
                        padding-left: 0;
                        padding-right: 0;
                        font-size: 11px
                    }
                }

                /* To keep things lined up for the relatively positioned items, these force style exists. They ensure that angular
                 * doesn't 'display: none' the elements, which would prevent them from being allowed to have a preset width */
                .force-inline {
                    display: inline !important;
                }

                .force-inline-block {
                    display: inline-block !important;
                }

                .product-price {
                    .product-uom { display: none; }
                }

                .product-data, .product-price, .product-ordering { display: inline }
            }
        }
    }
    .product-image, .product-data, .product-extras, .product-note, .product-price, .product-availability, .product-totals {
        .transition(opacity 1s ease);
    }
    .state-removed {
        .product-image, .product-data, .product-extras, .product-note, .product-price, .product-availability, .product-totals {
            .opacity(.30);
        }
    }
}

.grid-column-content {
    .product-listing-actions {
        width: 700px;
    }
    .cart-items{
        float:right;
        margin:6px 10px 10px 0;
    }
}

/*List of Lists*/
.shopping-list{
    .product-listing-actions{
        width:940px;
    }
}
/*Individual List*/
.list {
    .product-extras{display:none;}
}

@media (min-width:@screen-lg-min){
    .add-to-car-sidebar {display:none;}
}
