.dl-horizontal {
   position: relative;
   dt {
   float: left;
      width: 160px;
      clear: left;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 10px;
   }
   dd {
      margin-left: 180px;
      margin-bottom: 10px;
      &:before, &:after {
         //content: " ";
         display: table;
      }
      &:after {
         border-bottom: 1px solid #eee;
         width: 100%;
         position: absolute;
         left: 0;
         height: 5px;
      }
   }
}
