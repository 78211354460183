@import "global-variables";
.facet-group {
    .facet-close {
        cursor: pointer;
    }
    .facet {
        background: @color-interface-toolbar;
        padding: 10px;
        font-size: .95em;
        position: relative;
        + .facet { border-top: 1px darken(@color-interface-toolbar, 10%) solid; }
        .facet-title {
            .bold();
            //border-bottom: darken(@color-interface-toolbar, 10%) 1px solid;
            margin-bottom: 5px;
            //padding-bottom: 5px;
            color: darken(@color-interface-toolbar, 50%);
        }

        .facet-content, .facet-content-full {
            a:hover { text-decoration: none }
            .result-count {
                font-size: .8em;
                color: darken(@color-interface-toolbar, 50%);
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    margin: 4px 0;
                    li {
                        margin-left: 10px;
                    }
                    &.state-undo {
                        > a {
                            font-size: .90em;
                            &:before{
                                content:"\f104";
                                font-family: 'FontAwesome';
                                margin-right: 5px;
                            }
                        }
                    }
                    &.state-selected {
                        > span {
                            .bold();
                        }
                    }
                }
            }
        }
        .facet-view-all {
            position: absolute;
            top: 12px;
            right: 10px;
            font-size: .8em;
            color: @color-brand-primary;
            &:after {
                content:"\f105";
                font-family: 'FontAwesome';
                margin-left: 5px;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .facet-content-full {
            display: none;
            background: #eee;
            position: absolute;
            top: 5px;
            left: 202px;
            width:  300%;
            box-shadow: 0 0 10px #555;
            border: 1px #555 solid;
            z-index: 200;
            padding: 10px;
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                -moz-column-count: 3;
                -webkit-column-count:3;
                column-count:3;
                .heading {
                    font-size: 1.2em;
                    .bold();
                    margin-top: 5px;
                }
            }
        }
        &.state-open {
            .facet-content-full { display: block; }
            .facet-view-all {
                top: 9px;
                right: 16px;
                &:after {
                    content:"\f057";
                    font-family: 'FontAwesome';
                    font-size: 14px;
                    color: contrast(@color-brand-primary, white, black);
                }
            }
            .facet-title {
                background: @color-brand-primary;
                color: contrast(@color-brand-primary, white, black);
                padding-left: 15px;
                margin-left: -10px;
                margin-top: -5px;
                padding-top: 5px;
                &:after {
                    border: @color-brand-primary 13px solid;
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                    border-top-color: transparent;
                    position: absolute;
                    top: 5px;
                    right: -16px;
                    z-index: 1000;
                    content: "";
                }
            }
        }
    }
}

.form-inline {
    &:after{
        content:'';
        clear:both;
    }
    &.range {
        input {
            margin-bottom:15px;
        }
        .form-group {
            position:relative;
            width:48%;
            float:left;
            &:first-child{
                width:52%;
                padding-right:4%;
            }
        }
        .alert {
            clear:both;
        }
    }
}

.grid-column-tools {
	&.state-modal {
		.facet-group {
			width: 90%;
			.facet-content-full {
				ul {
					-moz-column-count: 1;
					-webkit-column-count: 1;
					column-count: 1;
				}
			}
		}
	}
}
