.page-quote-detail {
    .actions-links {
        .make-xs-column(3);
        > button, a {
            width: 100%;
            margin-bottom: 10px;
        }
        p {
          font-size: .85em;
          color: lighten(@color-interface-text, 10%);
          font-style:italic;
        }
    }
    .header-details {
        .make-xs-column(5);
    }
    .notes {
        .make-xs-column(4);
    }
    .grid-column-content { 
        padding: 15px;
    }
    .product-listing-holder.state-compact .product-listing-header {
        color: #333;
        font-size: 14px;
        font-weight: 700;
    }
    .product-listing-holder.state-compact .product-listing {
        font-size: 14px;
    }
    .product-listing-holder.state-compact .product-listing .product-mfg-no, .product-listing-holder.state-compact .product-listing .product-sup-no, .product-listing-holder.state-compact .product-listing .product-uom {
        font-size: 14px;
    }
    .product-listing-holder.state-compact .product-name {
        width: 38%;
    }
    .product-listing-holder.state-compact .product-sup-no {
        width: 22%;
    }
}