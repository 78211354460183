.product-summary-block {
    @base-color: darken(@color-interface-toolbar, 5%);
    border: 1px solid @base-color;
    min-width: 190px;
    dl {
        margin: 0;
    }
    .product-summary-title {
        background: @base-color;
        color: contrast(@base-color, #fff, #000);
        padding: 10px;
    }
    div {
        &.state-ambiguous .product-summary-line dd:before {
            content: "\f06a";
            font-family: FontAwesome;
            color: @color-semantic-warning;
            margin-right: 5px;
        }
    }
    .product-summary-line {
        margin: 0 10px 10px 10px;
        padding-top: 10px;
        position: relative;
        font-size: .9em;
        dt {
            font-weight: 400;
            white-space: pre;
            overflow: hidden;
        }
        dd {
            position: absolute;
            top: 10px;
            right: 0;
            background: #fff;
        }
        &.product-summary-rollup {
            dt, dd {
                font-weight: 600;
                font-size: 1.2em;
            }
        }
    }
    .product-summary-sub-line {
        &:extend(.product-summary-block .product-summary-line);
        color: lighten(@color-interface-text, 25%);
        font-size: .9em;
        margin: 0 10px 5px 10px;
        padding-top: 5px;
        dt {
            &:extend(.product-summary-block .product-summary-line dt);
            margin-left: 15px;
        }
        dd {
            &:extend(.product-summary-block .product-summary-line dd);
            &:after {
                &:extend(.product-summary-block .product-summary-line dd:after);
            }
            top: 5px;
            min-width: 70px;
            text-align: right
        }
    }
    .product-summary-line, div {
        + .product-summary-line, + div .product-summary-line {
            border-top: 1px dotted @base-color;
            margin-top: 10px;
        }
    }
    .cart-latest-pricing {
        position: relative;
        //background: lighten(@color-interface-toolbar, 3%);
        i {
            position: absolute;
            left: 15px;
            top: 40%;
            margin-top: -7px;
            font-size: 1.2em;
            color: darken(@color-interface-toolbar, 30%)
        }
        padding: 10px 10px 0 35px;
        font-size: .8em;
        color: lighten(@color-interface-text, 25%);
        //border-bottom: 1px solid lighten(@color-interface-toolbar, 1%);
    }

}

