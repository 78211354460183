.twitter-typeahead {
    width: 100%;
    display: table-cell !important;
    z-index: 1001 !important;
    .tt-hint {
        color: lighten(@color-interface-text, 50%);
    }
    .tt-dropdown-menu {
        background: white;
        width: 100%;
        box-shadow: 0 0 5px darken(@color-interface-toolbar, 10%);
        border: 1px solid darken(@color-interface-toolbar, 10%);
        .tt-suggestions {
            .tt-suggestion {
                border-width: 1px 0;
                border-style: solid;
                border-color: white;
                color: lighten(@color-interface-text, 20%);
                p {
                    padding: 6px 12px;
                    margin: 0;
                }
                &.tt-cursor {
                    background: @color-interface-toolbar;
                    color: @color-interface-text;
                    border-color: darken(@color-interface-toolbar, 10%);
                }
            }
        }
    }
}


/*<div class="tt-dataset-states">
    <span class="tt-suggestions" style="display: block;">
        <div class="tt-suggestion">
            <p style="white-space: normal;"><strong class="tt-highlight">De</strong>laware</p>
        </div>
        <div class="tt-suggestion tt-cursor">
            <p style="white-space: normal;">Rho<strong class="tt-highlight">de</strong> Island</p>
        </div>
    </span>
</div>*/
