@import "_bootstrap-variables";
@import "module-typography";

/* Structural
    These wrappers within each <section> provide the proper spacing
    and margins.
*/

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 970px;
    .container-wrapper {
        .make-row();
        > .container-full-column {
            .make-xs-column(12);
        }
        [data-ds-product-carousel] .container-full-column {
            .make-xs-column(12);
        }
        > h2 {
            margin-left: 15px;
            margin-right: 15px
        }
    }
}


/* Grids */

.grid-tools {
    .grid-column-tools {
        .make-xs-column(3);
        .facet-close {
            display: none;
        }
    }
    .grid-column-content {
        .make-xs-column(9);
    }
}

.grid-dual {
    .grid-column-content {
        .make-xs-column(7);
        &+ .grid-column-content {
            &:extend(.grid-dual .grid-column-tools);
        }
    }
    .grid-column-tools {
        .make-xs-column(5);
    }
}

.grid-preview {
    .grid-column-content {
        .make-xs-column(6);
    }
    .grid-column-tools {
        .make-xs-column(3);
        position: relative;
        .panel-bottom {
            border-top: 1px #ccc dotted;
            padding-top: 15px;
            margin-top: 15px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            &:before {
                position: absolute;
                top: -15px;
                width: 100%;
                left: 0;
                height: 15px;
                display: block;
                content: "";
                background: linear-gradient(top, fade(#fff, 0), #fff);
                border-bottom: 1px solid darken(@color-interface-toolbar, 6%);
            }
        }
        .panel-top {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        .panel-middle {
            overflow-y: auto;
            //background: green;
        }
    }
}

.grid-actions {
    .grid-column-tools {
        .make-xs-column(3);
        .grid-tools-block {
            + .grid-tools-block {
                margin-top: 15px;
            }
            .grid-tools-note {
                color: lighten(@color-interface-text, 25%);
                font-weight: 400;
                font-style: italic;
                font-size: .85em;
            }
            .fa-ul {
                font-size: .9em;
            }
        }
    }
    .grid-column-content {
        .make-xs-column(9);
    }
}

.grid-full {
    .grid-column-content {
        .make-xs-column(12);
    }
}


/* Inner Sections */

.grid-column-content section {
    + section {
        margin-top: 30px;
    }
    &.breakout {
        border: 1px solid darken(@color-interface-toolbar, 3%);
        border-left-width: 4px;
        border-left-color: darken(@color-interface-toolbar, 15%);
        padding: 15px;
        h2 {
            border-bottom: 1px solid darken(@color-interface-toolbar, 3%);
            background: lighten(@color-interface-toolbar, 2%);
            margin: -15px -15px 15px -15px;
            padding: 10px 15px;
            font-size: 1.1em;
        }
        h3 {
            &:extend(.grid-column-content section.breakout h2);
            font-size: 1em;
        }
        border-radius: 3px;
        footer {
            border-top: 1px solid darken(@color-interface-toolbar, 1%);
            background: lighten(@color-interface-toolbar, 4%);
            margin: 15px -15px -15px -15px;
            padding: 10px 15px;
            font-size: .95em;
            display: block;
            .section-caption {
                display: table-cell;
                width: 99%;
                vertical-align: bottom;
            }
            .section-summary {
                display: table-cell;
                width: 1%;
                vertical-align: bottom;
            }
        }
    }
    &.breakout-warning {
        &:extend(.grid-column-content section.breakout);
        border-color: lighten(@color-semantic-warning, 25%);
        border-left-color: @color-semantic-warning;
        h2 {
            &:extend(.grid-column-content section.breakout h2);
            background: lighten(@color-semantic-warning, 35%);
            border-color: lighten(@color-semantic-warning, 25%);
        }
        h3 {
            &:extend(.grid-column-content section.breakout-warning h2);
            font-size: 1em;
        }
    }
    &.breakout-error {
        &:extend(.grid-column-content section.breakout);
        border-color: lighten(@color-semantic-bad, 25%);
        border-left-color: @color-semantic-bad;
        h2 {
            &:extend(.grid-column-content section.breakout h2);
            background: lighten(@color-semantic-bad, 35%);
            border-color: lighten(@color-semantic-bad, 25%);
        }
        h3 {
            &:extend(.grid-column-content section.breakout-error h2);
            font-size: 1em;
        }
    }
}


/* Inner Layouts */

.grid-side-by-side {
    .make-row();
    .grid-column-content {
        .make-xs-column(7);
    }
    .grid-column-secondary-content {
        .make-xs-column(5);
    }
}


/* Column Set Up */

.grid-column-content-wrap {
    background: white;
    margin: auto;
    width: 100%;
    box-shadow: 0 0 5px darken(@color-interface-toolbar, 15%);
    padding: 20px;
    .struct-row {
        .make-row();
        .struct-column-content {
            .make-xs-column(8);
        }
        .struct-column-tools {
            .make-xs-column(4);
            .grid-tools-block {
                + .grid-tools-block {
                    margin-top: 15px;
                }
            }
        }
    }
}
