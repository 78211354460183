.waiting {
    @duration: 1.5s;
    position: relative;
    height: 200px;
    .waiting-label {
        text-transform: uppercase;
        color: darken(@color-interface-toolbar, 15%);
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        letter-spacing: .3em;
    }
    .waiting-node {
        width: 20px;
        height: 20px;
        background: @color-interface-toolbar;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        -webkit-animation-name: ds-wait;
        -webkit-animation-duration: @duration;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: linear;
        animation-name: ds-wait;
        animation-duration: @duration;
        animation-iteration-count: infinite;
        animation-direction: linear;
        left: 50%;
        margin-left: -50px;
        -webkit-animation-delay: ((@duration / 3) * 1);
        animation-delay: ((@duration / 3) * 1);
        + .waiting-node {
            margin-left: -10px;
            -webkit-animation-delay: ((@duration / 3) * 2);
            animation-delay: ((@duration / 3) * 2);
            + .waiting-node {
                margin-left: 30px;
                -webkit-animation-delay: ((@duration / 3) * 3);
                animation-delay: ((@duration / 3) * 3);
            }
        }
    }
}

@-webkit-keyframes ds-wait{
    0%{ background: @color-interface-toolbar }
    30%{ background: mix(@color-brand-primary, @color-interface-toolbar, 50%) }
    100%{ background: @color-interface-toolbar }
}

@keyframes ds-wait{
    0%{ background: @color-interface-toolbar }
    30%{ background: mix(@color-brand-primary, @color-interface-toolbar, 50%) }
    100%{ background: @color-interface-toolbar }
}