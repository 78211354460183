.page-help {
    .actions {
        .make-xs-column(6);
    }
    .instructions {
        .make-xs-column(6);
        b {
            display: block;
        }
    }
}
