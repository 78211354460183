@import "global-variables";

/*standard font sizes and weights*/

body {
    font-size: 1.4em;
    /*all ems based around this*/
    font-weight: 400;
}

.navbar {
    p,
    a,
    li {
        font-weight: 300;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
hgroup {
    font-weight: 400;
}

h1 {
    font-size: 2.1em;
    margin-top: 10px;
    + .horizontal-list {
        margin-top: -7px;
        color: lighten(@color-interface-text, 20%);
    }
}

h2 {
    font-size: 1.375em;
    color: @color-interface-text;
    padding-bottom: 10px;
    margin-top: 30px;
    &:first-child {
        margin-top: 0;
    }
    position: relative;
    a {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: .6em;
    }
    .note {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        right: 15px;
        font-size: .85em;
        color: lighten(@color-interface-text, 15%);
    }
    &:after {
                position: absolute;
                bottom: -10px;
                width: 100%;
                left: 0;
                height: 10px;
                display: block;
                content: "";
                background: linear-gradient(top, #fff, fade(#fff, 0));
        border-top: 1px solid darken(@color-interface-toolbar, 6%);
        z-index: 200;
            }
}

h3 {
    font-size: 1.25em;
    position: relative;
    a {
        position: absolute;
        top: 4px;
        right: 0;
        font-size: .6em;
    }
}

h4 {
    font-size: 1.125em;
}

h5 {
    font-weight: 700;
}

label {
    font-size: .9em;
    font-weight: normal;
    vertical-align: middle;
}

.contact-form label,
.sign-in-module label {
    font-weight: bold;
}

h5,
h6,
p {
    font-size: 1em;
}

.small-font {
    font-size: .9em;
}

.tiny-font {
    font-size: .8em;
}

.thin-rule {
    margin-top: 10px;
    margin-bottom: 10px;
}


/*Helper classes*/

.bold {
    font-weight: 600;
}

.status-approved {
    color: @brand-success;
}

.inline {
    display: inline;
}

.block {
    display: block;
}

// Helper Classes // -------------------------
.inline-label {
    font-weight: bold;
    float: left;
    width: 8em;
    margin-right: 1em;
    text-align: right;
    vertical-align: top;
}

.inline-content {
    margin-left: 9em;
}

.gray-font,
.typography-shade {
    color: lighten(@color-interface-text, 20%);
}

.typography-price {
    color: @color-brand-callout;
    text-align: right;
}

.center {
    text-align: center
}
