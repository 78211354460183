/* Custom Styling for DS Forms */

.btn-call-out {
    width: 100%;
}

.form-group {
    min-height: 25px;
    .btn {
        float: right;
    }
}

input[type="radio"] {
    margin-right: 5px;
}

.form-group {
    position: relative;
    .checkbox {
        position: absolute;
        bottom: 0;
        right: 10px;
        margin: 7px 0;
    }
}
