@import "global-variables";

.heroes {
  .make-row();
}

.hero-col-1 {
  .make-xs-column(12);
}

.hero-col-2-small {
  .make-xs-column(4);
}
.hero-col-2-large {
  .make-xs-column(8);
}

.hero-col-3 {
  .make-xs-column(4);
}