@import "global-variables";

/* Timeline
   ========================================================================== */

.timeline {
    @timeline-color-background: darken(@color-interface-toolbar, 10%);
    @timeline-color-selected: @color-brand-primary;
    @timeline-color-complete: mix(@timeline-color-background, @timeline-color-selected, 50%);
    @timeline-color-text: lighten(@color-interface-text, 20%);
    height: 30px;
    display: table;
    width: 100%;
    overflow: hidden;
    font-size: .85em;
    .timeline-node {
        display: table-cell;
        width: 1%;
        text-align: center;
        position: relative;
        vertical-align: top;
        border-top: 20px @timeline-color-background solid;
        padding-top: 5px;
        color: @timeline-color-text;
        p {
            font-size: .9em;
            font-weight: 400;
        }
        &.state-complete {
            border-color: @timeline-color-complete;
        }
        &.state-selected {
            border-color: @timeline-color-selected;
            font-weight: bold;
            color: @timeline-color-selected;
            + .timeline-node:before {
                border-right: 20px @timeline-color-background solid;
                border-bottom: @timeline-color-selected 20px solid;
                content: "";
                position: absolute;
                top: -20px;
                left: -20px;
                height: 0px
            }
            &:before {
                border-right: 20px @timeline-color-selected solid;
                border-bottom: @timeline-color-complete 20px solid;
                content: "";
                position: absolute;
                top: -20px;
                left: -10px;
                height: 0px
            }
        }
    }
}
