footer {
    background: @color-interface-toolbar;
    &.footer-platform {
        .container-wrapper {
            position:relative;
            height: 100px;
        }
        background: #fff;
        border-top: 1px solid darken(@color-interface-toolbar, 20%);
        font-size: .9em;
        a {
            color: @color-interface-text;
            &:hover {
                text-decoration: none;
                color: @color-brand-primary;
            }
        }
        .copyright {
            position: absolute;
            left: 15px;
            top: 30px;
        }
        .policies {
            .horizontal-list;
            font-size: 1em;
            position: absolute;
            right: 15px;
            top: 30px;
        }
        .powered-by {
            position: absolute;
            bottom: 10px;
            left: 50%;
            margin-left: -100px;
            text-align: center;
            width: 200px;
            font-size: .9em;
            color: #888;
            img {
                max-width: 100px;
                height: auto;
            }
        }
    }
    &.footer-instance {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 50px;
        border-top: 1px solid darken(@color-interface-toolbar, 10%);
        font-size: .95em;
        line-height: 1.51em;
        .powered-by {
            .make-xs-column(2);
            img {width: 100%;}
        }
        .footer-links {
            .make-xs-column(10);
        }
        .account-manager {
            .make-xs-column(5);
        }
        .customer-service {
            .make-xs-column(2);
        }
        .my-account {
            .make-xs-column(2);
        }
        .direct-supply-brands {
            .make-xs-column(3);

        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: 5px 0;
                padding: 0;
                a {
                    color: @color-interface-text;
                }
            }
        }
    }
}



/*}"><a href="">Direct Supply Equipment and Furnishings</a></li>
        <li class="brand-aptura"><a href="">Direct Supply Aptura</a></li>
        <li class="brand-tels"><a href="">Direct Supply TELS|Local Services</a></li>
        <li class="brand-tech"><a href="">Direct Supply Technology Solutions</a></li>
        <li class="brand-dssi*/

.sign-in {
    footer {
        &.footer-instance{
            display: none;
        }
    }
}

/*This is dumb.
.grid-column-tools  {
  min-height:400px !important;
}*/
