//
// Search
// --------------------------------------------------

#search {
  margin: 0 200px 0 100px;
  height: 46px;
  padding: 0 10px;
  //overflow: hidden;

  .input-group-btn:first-child > .btn {
    .border-left-radius(20px);
  }

  .input-group-btn:last-child > .btn {
    .border-right-radius(20px);
  }

  .input-group {
    margin-top: 4px;
    border: 4px solid (@color-brand-primary - 30);
    border-radius: 20px;
  }
  .input-group input {
    .border-left-radius(20px);
    width: 100%;
  }
  .btn-search i {
    color: @color-brand-primary;
  }
  .dropdown-menu {
    width: 100%;
    display: none;
    padding: 0;
    border-radius: 0;
    li {
      &:first-child {
        a {
          border-bottom: 1px dotted darken(@color-interface-toolbar, 10%);
        }
      }
      > a {
        padding: 3px 20px 3px 12px;
        .transition(~"color 250ms ease, background-color 250ms ease");
        color: lighten(@color-interface-text, 25%);
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &.active, &:focus, &:hover {
        > a {
          background-color: darken(@color-interface-toolbar, 2%);
          color: darken(@color-interface-text, 5%);
        }
      }
    }
  }

}
