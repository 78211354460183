@import "global-variables";

/* Top-Level Sections */
    * { margin: 0; }

/* Scaffold Structure */
    body {
        overflow-y: scroll;
        header { }
        //> nav { height: 50px; }
        > hgroup {
            margin-bottom: 15px;
        }
        > section, > .blt-wrap > section {
            margin-bottom: 30px;
            + section {
                margin-top: 30px;
            }
            &.attr-call-out {
                background: lighten(@color-interface-toolbar,4%);
                position: relative;
                overflow: hidden;
                padding-top: 15px;
                padding-bottom: 15px;
                &:before {
                    box-shadow: 0 0 10px darken(@color-interface-toolbar,10%);
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                };
                &:after {
                    box-shadow: 0 0 10px darken(@color-interface-toolbar,10%);
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                };
            }
        }
        &.task {
            background: @color-interface-toolbar;
            .container {
                width: 700px;
            }
            > section.highlight {
                margin-top: 30px;
                margin-bottom: 45px;
                .container {
                    position: relative;
                    .container-wrapper {
                        .grid-column-content {
                            position: static;
                            z-index: 1000;
                            padding-left: 30px;
                            width: 65%;
                            &:after {
                                box-shadow: 0 0 10px rgba(0,0,0,.3);
                                position: absolute;
                                top: -15px;
                                left: 15px;
                                bottom: -15px;
                                right: 15px;
                                background: white;
                                content: " ";
                                z-index: -1;
                            }
                        }
                        .grid-column-tools {
                            z-index: 1000;
                            padding-right: 30px;
                            width: 35%;
                        }
                    }
                }
            }
            footer.footer-instance {
                display: none;
            }
            .breadcrumb { display: none; }
        }
    }

/*Description Lists*/
dl {
  dd, dt{
    margin-bottom:2px;

  }

  input {
    margin-bottom:4px;
  }

}
