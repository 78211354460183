.promo-code-list {
    margin: 5px 0;
    padding: 0;
    list-style: none;
    li {
        color: lighten(@color-interface-text, 10%);
        &.promo-code-valid {
            color: @color-semantic-good;
        }
        &.promo-code-invalid {
            color: @color-semantic-bad;
        }
    }
}