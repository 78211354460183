@import "global-variables";

.shopping-list {

  h2 {
    margin-bottom: 15px;
  }
}

.title-input{
  width:50%;
  display:inline;
}

.delete-function {
  display:inline;
  position:absolute;
  right:30px;

  &:after {
    content:"\f00d";
    font-family: 'FontAwesome';
    font-size:.85em;
    padding-right:5px;
    padding-left:5px;
  }
  &:before {
    content: "Remove";
  }
}
.state-delete{
  background:@color-interface-toolbar;
  -webkit-transition: .25s linear color, .25s linear background-color, .25s linear border-color;
  transition: .25s linear color, .25s linear background-color, .25s linear border-color;
  .undo {
    &:after {
      content:"\f0e2";
      font-family: 'FontAwesome';
      font-size:.85em;
      padding-right:5px;
    }
    &:before {
      content:"Undo";
    }
  }
}
/*
.lists-edit tbody tr td{
  cursor:move;
  &:before {
    content:"\f135";
    font-family: 'FontAwesome';
    padding-right:5px;
    padding-left:5px;
  }
}*/
.lists-edit .error-text{
  position:absolute;
  padding-left:5px;
}