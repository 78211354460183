.artifact-card {
    @background-color: @color-interface-toolbar;
    @border-color: darken(@color-interface-toolbar, 10%);
    border: 1px solid @border-color;
    padding-top: 30px;
    position: relative;
    width: 100%;
    + .artifact-card {
        margin-top: 20px;
    }
    .artifact-identifier {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: @background-color;
        padding: 5px 10px;
        border-bottom: 1px solid @border-color;
        li {
            &:first-child {
                color: @color-brand-primary;
            }
        }
    }
    .artifact-sub-identifier {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px 10px;
    }
    .artifact-details {
        display: table;
        width: 100%;
        table-layout: fixed;
        .artifact-detail {
            display: table-cell;
            width: 1%;
            + .artifact-detail {
                border-left: 1px solid @border-color;
            }
            .artifact-detail-label {
                font-size: .9em;
                color: lighten(@color-interface-text, 40%);
                padding: 5px 10px 0 10px;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
            }
            .artifact-detail-value {
                position: relative;
                padding: 0 10px 5px 10px;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                &:after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);
                    background-repeat: repeat-x;
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffffff', GradientType=1);
                }
            }
        }
    }
}
