.virtual-catalog-container {
    margin-top: 45px;
    clear: both;
    .virtual-catalog-ribbon-wrapper {
        width: 675px;
        height: 46px;
        background: #b7daee;
        position: relative;
        /*margin-left: 15px;*/
        &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            border: 23px solid;
            right: -1.9em;
            z-index: -1;
            border-color: #b7daee;
            border-right-color: transparent;
        }
    }
    .virtual-catalog-ribbon {
        width: 672px;
        height: 44px;
        background: #ebf5fa;
        position: relative;
        margin: -45px 0 0 1px;
        z-index: 1;
        &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            border: 22px solid;
            right: -1.8em;
            z-index: -1;
            border-color: #ebf5fa;
            border-right-color: transparent;
        }
    }
    .virtual-catalog-wrapper {
        margin: -68px auto 0px;
        text-align: center;
        position: relative;
        z-index: 1;
        .virtual-catalog {
            position: relative;
            display: inline-block;
            a {
                float: left;
                display: inline-block;
            }
            img {
                &:extend(.virtual-catalog-container .virtual-catalog-wrapper .virtual-catalog a);
                max-width: 85px;
            }
            p {
                &:extend(.virtual-catalog-container .virtual-catalog-wrapper .virtual-catalog a);
                font-size: 16px;
                margin: 34px 20px 0 20px;
                padding: 0;
            }
            div {
                &:extend(.virtual-catalog-container .virtual-catalog-wrapper .virtual-catalog a);
                margin: 32px 0 0 0;
            }
            .btn {
                font-size: 12px;
                padding: 4px 10px;
            }
        }
    }
}
