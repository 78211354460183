header {
    #brand {
        margin: 10px 0;
        .make-xs-column(5);
        height: 45px;
        a {
            height: 100%;
            display: block;
        }
        img {
            height: 100%;
            width: auto;
        }
    }
    #utility {
        .make-xs-column(7);
        .utility-wrapper {
            float: right;
            width: 100%;
            padding: 9px 15px;
           /* background-color: @well-bg;
            border-left: 1px solid darken(@well-bg, 7%);
            border-right: 1px solid darken(@well-bg, 7%);*/
            font-size:.95em;
            box-sizing: border-box;
        }

        .nav-utility {
            text-align: right;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline;
            }
            li+li:before {
                padding: 0 5px 0 2px;
                color: (@gray + 100);
                content: "\007C";
            }
        }
        #location {
            float: right;
            margin-top: 10px;

            .dropdown-menu {
                z-index: 2000;
            }
        }
    }
}

.task {
    header {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dotted @color-interface-toolbar;
        #brand {
            padding: 10px 0;
            position: relative;
            float: left;
            width: 150px;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
            height: 65px;
            background: white;
            box-shadow: 0 0 10px rgba(0,0,0,.3);
            border-radius: 0 0 5px 5px;
            margin-left: 15px;
            margin-top: 0;
        }
        #utility {
            position: relative;
            min-height: 1px;
            padding-left: 30px;
            padding-right: 15px;
            margin-left: 150px;
            padding-top: 10px;
            float: none;
            width: auto;
        }
    }
}
