/* Artifact Block
 * Designed to showcase a product image and high-level information about an
 * artifact, such as a product.
 * -------------------- */

@import "global-variables";

.horizontal-list {
   margin: 0;
   padding: 0;
   clear: both;
   li {
      float: left;
      + li {
         &:before {
            content: "\2022";
            margin: 0 10px;
            color: lighten(@color-interface-text, 50%);
         }
      }
   }
   color: lighten(@color-interface-text, 20%);
   font-size: .9em;
   overflow: auto;
   list-style: none;
}
