.state-grid,
.state-compact-strict,
.state-detail {
    .product-listing.state-discontinued {
        overflow:hidden;

        > * {
            opacity:.5;
            transition:all .2s;

            &.replacement {
                position:absolute;
                top:0px;
                bottom:0px;
                left:100%;
                width:100%;
                padding:15px 0;
                background-color:#fff;
                opacity:1;
                overflow:hidden;
                z-index:2;
                transition:all .4s;

                .product-availability {
                        position:relative;
                        z-index:25;
                    }
            }
        }

        &:before {
            content:'';
            display:block;
            position:absolute;
            top:0px;
            left:0px;
            right:0px;
            bottom:0px;
            z-index:1;
            cursor:pointer;
        }

        &:hover {
            transition:all .4s;

            > * {
                opacity:0;

                &.replacement {
                    left:0px;
                    opacity:1;

                    &:before {
                        content:'';
                        display:block;
                        position:absolute;
                        top:0px;
                        left:0px;
                        right:0px;
                        bottom:0px;
                        z-index:10;
                        cursor:auto;
                    }
                }
            }
        }
    }
}

.state-grid {
    .product-listing.state-discontinued > *.replacement {
        padding:0 25px;

        .product-availability {
            display:none;
        }
    }
}

.state-compact-strict {
    .product-listing.state-discontinued {
        > *.replacement .product-availability {
            position:absolute;

            .btn-sm {
                padding-top:3px;
                padding-bottom:3px;
            }
        }
    }
}
