@import "global-variables";
//@import "../../lib/less-prefixer/prefixer";

.duel-toolbar {
    width: 100%;
    margin-bottom: 20px;
    .duel-toolbar-heading {
        display: none;
        .duel-toolbar-description {
            color: @corp-gray;

        }
    }
    .duel-toolbar-actions {
       // border-bottom: 1px solid lighten(@corp-gray, 55%);
       // background: @color-interface-toolbar;
        //border-radius: 4px;
      //  height: 40px;
        height:20px;
        position: relative;
        width: 100%;
        .duel-toolbar-item {
            position: absolute;
            left: 5px;
        }
        .duel-toolbar-aggregate {
            position: absolute;
            right: 5px;
            .duel-toolbar-label {
                float: left;
                font-size: 13px;
                line-height: 30px;
                margin-right: 5px
            }
            .btn-group+.btn-group {
                margin-left: 15px;
            }
            .duel-toolbar-view {
                button {
                    padding: 3px 10px;
                    font-size: 14px;
                }
                .state-selected,
                .state-selected i:before {
                    @tmp-color: lighten(@color-brand-primary, 10%);
                    color: @tmp-color;
                    text-shadow: fade(lighten(@tmp-color, 20%), 40%) 0 0 5px;
                }
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .duel-toolbar {
        .duel-toolbar-description { display: none; }
        .duel-toolbar-aggregate {
            .duel-toolbar-view { display: none; }
            .grid-tools-control { display: inline-block; }
        }
    }
}
