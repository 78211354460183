@import "global-variables";

.secondary-search-box {
  position:absolute;
  top:0;
  right:130px;
  background-color: @color-interface-toolbar;
  border: 1px solid darken(@color-interface-toolbar, 15%);
  width:250px;
  padding:4px;
  z-index: 100;

  input {
    border-radius: 12px;
    width:202px;
  }

  .btn {
    color: @color-brand-primary;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
  }

  p {
    margin:0;
  }
}

.data-table {
  .order-num-col{
    min-width: 85px;
  }
  .order-date-col{
    min-width:85px;
  }
  .order-by-col{
    min-width:80px;
  }
  .status-col{
    min-width:95px;
  }
  .facility-col{
    min-width:90px;
  }
  .supplier-col{
    min-width:70px;
  }
  .price-col{
    min-width: 40px;
    .td-right;
  }

}