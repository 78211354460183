.category-resources-list {
    overflow: auto;
    padding-left:20px;
    list-style:none;
    li {
      font-size:.95em;
      line-height:1.8;
      position:relative;
      float:left;
      display:inline;
      width:50%;
    }
    li.pdf:before {
      position:absolute;
      font-family: FontAwesome;
      top:0;
      left:-20px;
      content: "\f1c1";
    }
    li.video:before {
      position:absolute;
      font-family: FontAwesome;
      top:0;
      left:-20px;
      content: "\f16a";
    }
}
