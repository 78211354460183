/* DS icon, specific to individual suppliers
    and product statuses */

/* DS Icons */

.ds-icons {
    font-size: .85em;
    .typography-shade();
    margin-bottom: 3px;
    &:before {
        font-family: FontAwesome;
        content: "\f140";
        margin-right: 8px;
        font-size: 1.3em;
    }
    &.demand-status:before {
        content: "\f017";
        color: @color-alt4;
    }
    &.prev-purch:before {
        content: "\f1da";
        color: @color-alt1;
    }
    &.marketing:before {
        content: "\f091";
        color: @color-alt3;
    }
    &.shipping:before {
        content: "\f0d1";
        color: @color-alt2;
    }
    &.preferred:before {
        content: "\f069";
        color: #cc0000;
    }

}

.glyphicon {
    .fa-icon;
    &.glyphicon-chevron-left:before {
        content: @fa-var-chevron-left;
    }

    &.glyphicon-chevron-right:before {
        content: @fa-var-chevron-right;
    }
}
