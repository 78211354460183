.home {
    .home-actions {
        .make-xs-column(3);
        .quick-order {
            margin-top: 200px;
            height: 230px;
            background: lighten(@color-interface-toolbar, 3%);
            position: relative;
            border: 1px solid darken(@color-interface-toolbar, 10%);
            .qo-title {
                background: darken(@color-interface-toolbar, 50%);
                color: white;
                padding: 10px;
            }
            .qo-action {
                padding: 10px;
                width: 100%;
                button {
                    width: 100%;
                }
                position: absolute;
                bottom: 0;
                left: 0;
            }
            .qo-body {
                .qo-row {
                    margin: 10px;
                    text-align: center;
                    .qo-row-label {
                        width: 130px;
                        float: left;
                        height: 30px;
                        + .qo-row-label {
                            margin-left: 10px;
                            width: 50px;
                            text-align: center;
                        }
                    }
                }
                input {
                    display: inline;
                    width: 130px;
                    height: 25px;
                    + input {
                        margin-left: 10px;
                        width: 50px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .home-ads, .home-top {
        .make-xs-column(9);
        margin-top: 10px;
        .hero-spot {
            display: block;
            margin-bottom: 30px;
            img {
                height: auto;
            }
        }
        .ad-spots, .home-secondary {
            display: block;
            float: left;
            height: auto;
            + .ad-spots {
                margin-left: 31px;
            }
        }
    }

    &.home-advantage {
        .home-actions {
            .quick-order {
                margin-top:10px;
            }
        }
        .supplier-list {
            margin-bottom:@padding-base-horizontal*2;
            border-bottom:1px solid @gray-lighter;
            padding-bottom:@padding-base-horizontal;

            > a {
                padding:@padding-base-horizontal/2;
            }

            ul {
                .clearfix;
                padding:0;
                margin:0 -@padding-base-horizontal;
                list-style:none;
                li {
                    width:25%;
                    height:120px;
                    line-height:105px;
                    padding:0 @padding-base-horizontal @padding-base-horizontal;
                    float:left;
                    a {
                        display:block;
                        position:relative;
                        height:100%;
                        width:100%;
                        border:1px solid @gray-lighter;
                        background-color:@gray-lighter;
                        overflow:hidden;
                        text-align:center;
                        #gradient > .vertical(white; @gray-lighter);

                        img {
                            display:inline;
                            height:auto;
                            max-width:80%;
                            max-height:80%;
                            vertical-align: middle;

                        }

                    }
                }
            }
        }
        .content-tables {
            margin-bottom:@padding-base-horizontal*2;
            border-bottom:1px solid @gray-lighter;
            padding-bottom:@padding-base-horizontal;

            h3 {
                padding-left:@padding-base-horizontal/2;
            }

            a {
                padding:@padding-base-horizontal/2;
            }
            .content-table-row {
                a {
                    display:block;
                    border-bottom:1px dotted @gray-light;
                    overflow:hidden;
                    color:@gray-light;
                    text-decoration:none;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    &:hover {
                        background:@gray-lighter;
                    }
                }
                &:first-child {
                    padding-top:0;
                    border-top:1px dotted @gray-light;
                }
                span {
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow:clip;
                    &.content-table-date {
                        display:inline-block;
                        vertical-align:middle;
                        width:150px;
                        color:@brand-info;
                    }

                    &.content-table-subject {
                        color:black;
                    }

                    &.content-table-summary:before {
                        content:'•';
                        padding-left:@padding-base-horizontal;
                        padding-right:@padding-base-horizontal;
                    }
                }
            }
            &.x-locked-summary {
                .content-table-row{
                    .content-table-subject {
                        width: 200px
                    }

                    .content-table-summary:before {
                        content:'';
                    }
                }
            }
        }
        }
}


       .quick-order-new {
              margin-left: auto;
              margin-right: auto;
              width: 500px;
              text-align: center;
       }

       .qo-body-new {
              float: left;
              width: 100%;
       }

       .qo-row-new {
              margin: 10px;
       }

       .qo-item-label {
              float: left;
              width: 80%;
              margin: 0 0 5px 18px;
              text-align: left;
       }

       .qo-qty-label {
              float: left;
              margin-left: 10px;
              width: 35px;
              text-align: center;
       }

       .qo-row-new > input {
              display: inline;
       }

       .qo-itemnum {
              width: 80%;
       }

       .qo-qty {
              margin-left: 10px;
              width: 50px;
           text-align: center;
       }

       .affixed-bar {
              padding: 10px 0;
              height: 53px;
              position: fixed;
              bottom: 0;
              z-index: 10;
              box-shadow: -1px 2px 15px -3px #BDBDC2 inset;
              width: 100%;
              left: 0;
              background: #F2F2F3 none repeat scroll 0 0;
       }

       .affixed-button-container {
              margin-left: auto;
              margin-right: auto;
              width: 450px;
              overflow: hidden;
       }

       .affixed-button {
              float: right;
       }
