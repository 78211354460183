.summary {
    width: 200px;
    float: right;
    border-top: 3px solid @color-interface-toolbar;
    .summary-line {
        padding: 5px 0;
        font-size: 12px;
        overflow: auto;
        border-bottom: darken(@color-interface-toolbar, 20%) 1px solid;
        .summary-line-label {
            float: left;
            width: 115px;
            padding-left: 10px;
        }
        .summary-line-value {
            float: left;
            width: 85px;
            text-align: right;
            padding-right: 10px;
        }
    }
    .summary-line-total { font-weight: bold; background:@color-interface-toolbar;  }
}