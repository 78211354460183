@import "global-variables";
//
// Site Theme Specific Styles
// --------------------------------------------------
//General Styling
table {
    line-height: 200%;
    th { font-size: .9em; font-weight: 600;}
    tr {
        +tr {
            border-top: 1px solid @color-interface-toolbar;
        }
    }
    &.selectable {
        tbody{
            tr{
                cursor: pointer;
                transition: background 100ms linear;
                &:hover {
                    background: fade(@color-brand-primary, 20%);
                    transition: background 250ms linear;
                }
            }
        }
    }
}
//Specific Table types
.attribute-table {
    line-height: 150%;
    width: 100%;
    tr {
        td {
            padding: 6px;
            width: 50%;
            vertical-align: top;
            &.spec-label {
                color: lighten(@color-interface-text, 25%);
            }
        }
        &.action-row {
            border-bottom: none;
            td {
                text-align: right;
            }
        }
    }
}
.rollup-table {
    line-height: 150%;
    min-width: 60%;
    table-layout: fixed;
    width: auto;
    tr {
        td {
            padding: 6px;
            width: 1%;
            vertical-align: top;
        }
        td:last-child {
            width: 98%;
        }
    }
}
.data-table {
    width: 100%;
    th {
        .table-header;
        border-top: 0;
        background: #eee;
    }
    tbody {
        .small-font;
        vertical-align: top;
    }
    tbody tr, thead tr {
        line-height: 1.9em;
    }
    td, th {
        padding: 5px 10px;
    }
}

//Helper classes
.td-right {
    text-align: right;
}
.table-header {
    .small-font;
    .bold;
}
