.tags {
    margin: 5px -2px 0 -2px;
    padding: 0;
    list-style: none;
    .tag {
        .tag-coloring(@color-interface-toolbar);
        padding: 3px 25px 3px 5px;
        font-size: .9em;
        float: left;
        margin: 2px;
        position: relative;
        border-radius: 2px;
        &.state-invalid {
            .tag-coloring(lighten(@color-semantic-bad, 55%));
        }
        &.state-unknown {
        }
        &.state-valid {
            .tag-coloring(lighten(@color-semantic-good, 55%));
        }
        .tag-remove {
            height: 20px;
            line-height: 20px;
            text-align: center;
            position: absolute;
            right: 2px;
            width: 20px;
            top:50%;
            margin-top: -10px;
            border-radius: 10px;
            z-index:3;
            cursor: pointer;
            .transition(.25s linear opacity);
            display: block;
            .opacity(1);
        }
        .tag-restore {
            .transition(.25s linear opacity);
            display: block;
            .opacity(0);
            height: 20px;
            line-height: 20px;
            text-align: center;
            position: absolute;
            right: 2px;
            width: 20px;
            top:50%;
            margin-top: -10px;
            border-radius: 10px;
            z-index:1;
            cursor: pointer;
        }
        &.state-removed {
            .tag-restore {
                .opacity(1);
                z-index: 3;
            }
            .tag-remove {
                .opacity(0);
                z-index: 1;
            }
        }
    }
}

/* Mixin */
.tag-coloring(@color) {
    background: @color;
    border: 1px solid darken(@color, 30%);
    color: contrast(@color, hsl(hue(@color), saturation(@color), 20%), hsl(hue(@color), saturation(@color), 80%));
    -webkit-transition: .25s linear color, .25s linear background-color, .25s linear border-color;
    transition: .25s linear color, .25s linear background-color, .25s linear border-color;
    &.state-removed {
        background: mix(@color, #fff, 35%);
        border: 1px solid mix(darken(@color, 30%), #fff, 35%);
        color:mix(contrast(@color, hsl(hue(@color), saturation(@color), 20%), hsl(hue(@color), saturation(@color), 80%)), #fff, 45%);
    }
    .tag-remove, .tag-restore {
        color: contrast(@color, hsl(hue(@color), saturation(@color), 40%), hsl(hue(@color), saturation(@color), 60%));
        &:hover {
            color: contrast(@color, hsl(hue(@color), saturation(@color), 20%), hsl(hue(@color), saturation(@color), 80%));
        }
    }
}