.navbar {
  .box-shadow(0px 1px 3px rgba(0,0,0,.5));
  
  .navbar-form {
	  margin-bottom: 0;
  }
    .dropdown-menu {
        width: 213px;
    }
    .icon {
        text-align: center;
        padding-top: 8px;
        img { width: 60px }
        div { color: white; font-size: 10px; }
    }
}

.navbar-nav li, .navbar-nav.navbar-right li:first-child {
  border-right: 1px solid (@color-brand-primary - 15);
  border-left: 1px solid (@color-brand-primary + 15);
}
.navbar-nav.navbar-left, .navbar-nav.navbar-right {
  border-left: 1px solid (@color-brand-primary - 15);
  border-right: 1px solid (@color-brand-primary + 15);
}
.navbar-nav > li > a:hover {
  background-color: lighten(@corp-accent, 5%);
}

.navbar-nav li .dropdown-menu li, .navbar-nav.navbar-right li .dropdown-menu li {
  border-left: none;
  border-right: none;
}

@media (max-width: @screen-sm-min) {
  .navbar-nav {
    margin: 0 -15px;
  }
}

