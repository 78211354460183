@import "_bootstrap-variables";

.page-purchase-order {
    .grid-shipping-address{
        .make-xs-column(4);
    }
    .grid-details{
        .make-xs-column(5);
    }
    .grid-actions {
        .make-xs-column(3);
        button { width: 100%; margin-bottom: 10px; }
        p { font-size: .85em; color: lighten(@color-interface-text, 10%);
            font-style:italic; }
    }
    .grid-approval-timeline{
        .make-xs-column(6);
    }
    .grid-approval-policy{
        font-size: .85em;
        .make-xs-column(6);
    }
}