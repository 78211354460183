@import "global-variables";

.side-tab {

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .facet-tab-title {
    .bold();
    position: relative;
    background: darken(@color-interface-toolbar, 20%);
    color: contrast(@color-interface-toolbar, white, black);
    padding:10px;
    font-size: .95em;
    cursor:pointer;
    cursor: hand;

    &:after {
      content:"\f067";
      font-family: 'FontAwesome';
      font-size:.85em;
      position:absolute;
      right: 15px;
      top:12px;
    }
  }

  .facet-tab-title:hover{
    background: @color-brand-primary;
    color: contrast(@color-brand-primary, white, black);
  }

  .facet-open {

    background: @color-brand-primary;
    color: contrast(@color-brand-primary, white, black);

      &:after {
        content:"\f068";
        font-family: 'FontAwesome';
        font-size:.85em;
        position:absolute;
        right: 15px;
        top:12px;
      }
  }
  .invisible{
    visibility: hidden;
  }

  .facet-group {
    background: @color-interface-toolbar;
    padding-top:8px;
  }

}