.profile-card {
    position:relative;
    display:inline-block;
    float:left;
    border:1px solid transparent;
    padding:15px 15px 15px 90px;
    border-radius:@border-radius-base;
    width:50%;
    background-color:white;
    overflow:hidden;
    cursor:default;
    -ms-transition:all .3s;
    -moz-transition:all .3s;
    -webkit-transition:all .3s;
    transition:all .3s;

    .figure {
        position:absolute;
        top:15px;
        left:15px;
        width:60px;
        height:60px;
        border:1px solid @gray-light;
        border-radius:50%;
        background-color:@gray-lighter;
        text-align:center;
        vertical-align:text-bottom;
        color:@gray;
        overflow:visible;
        * {
            vertical-align:text-bottom;
            line-height:60px;
        }

        i.fa {
            font-size:@font-size-h3;

            &[class*="lock"] {
                display:none;
                position:absolute;
                bottom:0px;
                right:0px;
                line-height:1em;
                color:@gray-darker;
            }

            &[class*="check"] {
                display:none;
                position:absolute;
                bottom:0px;
                right:0px;
                line-height:1em;
                color:@brand-success;
            }
        }


    }

    div {
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
    }

    .controls {
        text-overflow: auto;
        overflow:auto;
        white-space:normal;
        position:absolute;
        top:0px;
        right:0px;
        margin:15px;
        border-left:1px solid @gray-light;
        padding-left:15px;
        background-color:white;
        box-shadow:-25px 0 10px white;
        opacity:0;
        display:none \9; /* IE Hack */
        -ms-transform:translate(150%,0);
        -moz-transform:translate(150%,0);
        -webkit-transform:translate(150%,0);
        -transform:translate(150%,0);
        -ms-transition:all .3s;
        -moz-transition:all .3s;
        -webkit-transition:all .3s;
        transition:all .3s;
    }

    &.locked {
        .figure {
            i.fa {
                &[class*="lock"] {
                    display:block;
                }
            }
        }
    }

    &.selected {
        .figure {
            i.fa {
                &[class*="check"] {
                    display:block;
                }
            }
        }
    }

    &:hover {
        border-color:@gray-light;
        box-shadow:0 0 15px @gray-light;
        z-index:1;
        -ms-transform: scale(1.02);
        -moz-transform: scale(1.02);
        -webkit-transform: scale(1.02);
        transform: scale(1.02);

        .controls {
            opacity:1;
            -ms-transform:translate(0,0);
            -moz-transform:translate(0,0);
            -webkit-transform:translate(0,0);
            transform:translate(0,0);
            display:block \9; /* IE Hack */
            }

    }
}

.account-settings {
    .duel-toolbar-aggregate {
        top:0px;
        label {
            margin:6px;
        }
    }

    #scroll-list {
        max-height:100px;
        overflow-y:auto;

        .checkbox {
            margin:0;
        }
    }
}

.split-row {
    margin-left:-@grid-gutter-width/2;
    margin-right:-@grid-gutter-width/2;

    &:after {
        content:'';
        display:block;
        clear:both;
    }

    > * {
        float:left;
        width:50%;
        padding-left:@grid-gutter-width/2;
        padding-right:@grid-gutter-width/2;
    }
    &.by-three {
        > * {
            width: 45%;
        }
        > div:last-child { width: 10% }
    }
}

                .one-line-collapser {
                    margin: 5px -5px;
                    border: 1px solid transparent;
                    max-height: 30px;
                    overflow-y: hidden;
                    padding: 5px;
                    cursor: pointer;
                    position: relative;
                    &:after{
                        content: "\f055";
                        font-family: "FontAwesome";
                        position: absolute;
                        right: 10px;
                        top: 0;
                                transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        color: #bbb;
                    }
                    &:hover {
                        background: #f5f5f5;
                        &:after {
                            color: #336699;
                        }
                    }
                    &.open {
                        border-color: #ccc;
                        max-height: 200px;
                        overflow-y: auto;
                        background: #f5f5f5;
                            &:after{
                                content: "\f056";
                            }
                    }
                    > table {
                        margin-top: 5px;
                    }
                }
