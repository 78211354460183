body {
    > * {
        transition: .5s ease opacity, .5s ease filter, .5s ease -webkit-filter;
        -webkit-transition: .5s ease opacity, .5s ease filter, .5s ease -webkit-filter;
    }
    &.is-blurred {
        > * {
            .opacity(.2);
            filter: blur(5px);
            -webkit-filter: blur(5px);
        }
        .ds-load-modal {
            top: 50%;
        }
    }
    .ds-load-modal {
        transition: .5s ease top;
        -webkit-transition: .5s ease top;
        opacity: 1;
        filter: none;
        -webkit-filter: none;
        position: fixed;
        top: -200px;
        left: 50%;
        margin-top: -150px;
        margin-left: -150px;
        line-height: 300px;
        text-align: center;
        width: 300px;
        height: 300px;
        background: -webkit-radial-gradient(hsl(0, 100%, 100%), hsla(0, 0%, 0%, 0) 70%);
        i {
            font-size: 4em;
        }
        span {
            display: block;
            font-size: 1em;
            font-weight: bold;
            margin-top: -255px
        }
    }
}

html.eq-ie8 {
    .modal-window-narrow {
        width: 100%;
       
        .modal-dialog {
            width: 400px;
            margin: 30px auto;
        }
    }
}

@media screen and (min-width: @screen-sm-min) {
    .modal-window-narrow {
        .modal-dialog {
            width: 400px;
            margin: 30px auto;
        }
    }
}