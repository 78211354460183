#BVRRSummaryContainer { height: 48px }


/* Undoing Bootstrap hack for IE8 & 9 */

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  line-height: normal;
}
