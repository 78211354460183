.product-carousel {
    .carousel-holder { overflow: hidden }
    position: relative;
    .carousel-controls {
        position: absolute;
        top: -45px;
        right: 0;
        div {
            display: inline-block;
        }
        .carousel-data {
            width: 60px;
            text-align: center;
        }
    }
    .product-listing-holder-row {
        //.make-xs-column(9);
    }
}
