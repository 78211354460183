@media print {
    .container {
        width: 100%;
        padding: 0;
        .container-wrapper {
            margin: 0
        }
    }
    header #brand {
        text-align: center;
        width: 100%;
    }
	h1 {
        font-size: 1.5em;
        border-bottom: 1px solid #999;
        text-align:center;
        padding-bottom: 20px;
    }
    nav, #utility, .breadcrumb, footer, .privacy, [role="toolbar"] { display: none }
    .grid-tools {
      .grid-column-content { width: 100%; }
      .grid-column-tools { display: none; }
    }
    .grid-actions {
      .grid-column-content { width: 100%; }
      .grid-column-tools { display: none; }
    }
    .page-purchase-order {
        .grid-actions { display: none }
        .grid-details { width: 60% }
        .grid-shipping-address { width: 40%; }
    }
}
