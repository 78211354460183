.category-list {
    .category-holder-row {
        .make-row();
        .category-item {
            .make-xs-column(3);
            min-height: 198px;
            margin-bottom: 30px;
            a {
                display: block;
                position: relative;
                img {
                    width: 100%;
                    height: auto;
                }
                .category-name {
                    width: 100%;
                    font-size: 1em;
                    margin-top: 5px;
                }
            }
            ul {
                list-style: none;
                margin: 3px 5px;
                padding: 0;
                font-size: 1em;
            }
        }
    }
    &.guided-nav {
        .category-holder-row {
            border-bottom: 1px dotted @color-interface-toolbar;
            margin-bottom: 27px;
            .category-item {
                margin-bottom: 0;
                a {
                    .category-name {
                        font-size: .9em;
                    }
                }
            }
        }
    }
}
