//
// Breadcrumbs (test)
// --------------------------------------------------


.breadcrumb {
  font-size:0.85em;
  margin-top:-10px;
  margin-left:-38px;
  list-style: none;
  border-radius: @border-radius-base;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  &:after {
    width: 70px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    #gradient > .horizontal(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    content: " "
  }
  > li {
    display: inline-block;
    margin-right: 1px;
    + li:before {
      content: "\f105\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
        font-family: FontAwesome;
    }
  }
  > .active {
    color: @breadcrumb-active-color;
  }
}
